import React from "react";
import PropTypes from "prop-types";
import { Alert, Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Banner({
  severity = "info",
  multi = false,
  title,
  body,
  actions,
  showCloseIcon = true,
  setShowBanner
}) {
  return (
    <Alert
      severity={severity}
      sx={{
        "& .MuiAlert-action": { paddingTop: "0" }
      }}
      action={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {actions}
          {showCloseIcon && (
            <IconButton
              onClick={() => setShowBanner(false)}
              size="small"
              color="inherit"
              aria-label="Close">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      }>
      <Box>
        {title && (
          <Typography
            sx={{ fontWeight: "bold", marginRight: multi ? "" : "8px" }}
            component={multi ? "p" : "span"}>
            {title}
          </Typography>
        )}
        {body}
      </Box>
    </Alert>
  );
}

Banner.propTypes = {
  severity: PropTypes.string,
  multi: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  actions: PropTypes.node,
  setShowBanner: PropTypes.func
};
