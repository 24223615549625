import React from "react";
import { useState, useEffect } from "react";
import { onSnapshot, collection, updateDoc, doc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { Box, Button, Typography } from "@mui/material";
import { ACCEPTENCE_STATUS, COURSE_MATERIAL_TYPE } from "./consts";
import { useQuery } from "../../../../hooks";
import { httpCallables } from "../../../../firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import { enqueueFlashMessage } from "../../../../redux/userSlice";
import { selectAlertsDuration } from "../../../../redux/firestoreSelectors";
import { useIntl } from "react-intl";
import { fetchTeacherCourseTasks } from "../../../../redux/tasksSlice";
import { setTexts } from "../../../../redux/textsSlice";
import useGetTheme from "../../../../hooks/useGetTheme";
import Banner from "../../../SharedComponents/Banner";

const ExportBanner = ({ bannerType, setBannerShown }) => {
  const firestore = useFirestore();
  const { course_id } = useQuery();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme();

  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  const courseMaterialRef = collection(firestore, "course_material");
  const [courseMaterialData, setCourseMaterialData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCourseMaterialStatusUpdate = async (item, statusUpdate) => {
    if (!item.id) {
      console.error("Document ID is undefined or null");
      return;
    }
    try {
      const docRef = doc(firestore, `course_material/${item.id}`);
      await updateDoc(docRef, {
        status: {
          ...item.status,
          [course_id]: statusUpdate
        }
      });
      console.log("Document status updated successfully.");
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const handleShareCourseMaterial = async (item) => {
    try {
      setLoading(item.id);
      const payload = {
        ...item,
        receiverCourseId: Number(course_id)
      };
      const response = await httpCallables.coursesFunctions({
        func_name: "handleShareCourseMaterial",
        payload
      });

      if (response?.data?.success) {
        setLoading(false);
        handleCourseMaterialStatusUpdate(item, ACCEPTENCE_STATUS.ACCEPTED);
        dispatch(fetchTeacherCourseTasks({ course_id }));
        httpCallables.readUserCoursesAndTexts().then(({ data }) => {
          const { texts } = JSON.parse(data);
          dispatch(setTexts(texts));
        });
      }
    } catch (error) {
      console.error("Error sharing course material:", error);
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "shareCourseMaterial.error",
            defaultMessage: "Couldn't share course material. Please try again."
          }),
          duration: alertsDuration
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(courseMaterialRef, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data.type === bannerType &&
          data.courses.includes(Number(course_id)) &&
          data.status[course_id] === ACCEPTENCE_STATUS.PENDING
        ) {
          newData.push({ id: doc.id, ...data }); // Include the document ID in the data object
        }
      });
      setCourseMaterialData(newData);
      setBannerShown(newData.length > 0);
    });

    return () => unsubscribe();
  }, [firestore, bannerType, course_id]);

  const renderBannerContent = () => {
    if (!courseMaterialData.length) return null;
    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        {courseMaterialData.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: "16px",
              backgroundColor: "background.default",
              position: "absolute",
              width: "100%",
              zIndex: "1000"
            }}>
            <Banner
              title="New course materials"
              body={`${item.sender} wants to share some course materials with you`}
              actions={
                <>
                  <Button
                    size="medium"
                    onClick={() =>
                      handleCourseMaterialStatusUpdate(
                        item,
                        ACCEPTENCE_STATUS.DENIED
                      )
                    }>
                    Deny
                  </Button>
                  <Button
                    onClick={async () => {
                      handleShareCourseMaterial(item);
                    }}
                    size="medium">
                    Accept
                  </Button>
                </>
              }
              showCloseIcon={false}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return renderBannerContent();
};

export default ExportBanner;
