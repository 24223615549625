// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../hooks";
import { captureException } from "../../../utils/errorHandlers";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import {
  setSubmittedStudentTask,
  setSubmittedStudentReflection,
  setSubmittedStudentReview,
  setSentFeedbackTask
} from "../../../redux/taskSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectCourse } from "../../../redux/coursesSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Components
import TasksTeacherView from "../../Tasks/TaskManager/TasksTeacherView";
import TasksStudentView from "../../Tasks/TaskManager/TasksStudentView";
import ScrollBox from "../../SharedComponents/ScrollBox";
import { selectAlertsDuration } from "../../../redux/firestoreSelectors";
import ExportBanner from "./courseMaterialExport/ExportBanner";
import { COURSE_MATERIAL_TYPE } from "./courseMaterialExport/consts";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      minHeight: "70%",
      // width: "83.333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",
      // margin: "0 auto",

      [theme.breakpoints.up("desktop")]: {
        // maxWidth: 900,
        marginInline: theme.spacing(8)
      }
    }
  };
});

export default function TaskManager(props) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourse?.id
  );
  const [refreshToken, setRefreshToken] = React.useState(0);

  const intl = useIntl();
  //Redux

  const course = useSelector((state) => selectCourse(state, selectedCourseId));

  const sentFeedbackTask = useSelector((state) => state.task.sentFeedbackTask);
  const sentFeedbackTextTask = useSelector(
    (state) => state.task.sentFeedbackTextTask
  );
  const shouldUndo = useSelector((state) => state.user.undo);
  const submittedStudentTask = useSelector(
    (state) => state.task.submittedStudentTask
  );
  const submittedStudentReflection = useSelector(
    (state) => state.task.submittedStudentReflection
  );
  const submittedStudentResponse = useSelector(
    (state) => state.task.submittedStudentResponse
  );
  const submittedStudentReply = useSelector(
    (state) => state.task.submittedStudentReply
  );
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  const submissions = useSelector((state) => state.tasks.submissions);

  const [bannerShown, setBannerShown] = useState(false);

  // TODO: need to refactor the undos to work on submission ID and not task_id
  // once this is done, remove getTaskSubmission
  function getTaskSubmission(task_id) {
    submissions.find((submission) => submission.task_id === task_id);
  }
  //Behavior

  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoStudentTaskSubmission = () => {
      const taskId = submittedStudentTask;

      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoStudentTaskSubmissions"
      );

      firebaseFunction({
        task_id: taskId
      }).then((response) => {
        dispatch(setSubmittedStudentTask(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/task?task_id=${taskId}`);
      });
    };

    const undoSubmitPeerReviewReflection = () => {
      const taskId = submittedStudentReflection;
      const submission_id = getTaskSubmission(taskId);
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewReflection"
      );

      firebaseFunction({
        submission_id: submission_id
      }).then((response) => {
        dispatch(setSubmittedStudentReflection(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/task?task_id=${taskId}`);
      });
    };

    const undoSubmitPeerReviewResponse = () => {
      const taskId = submittedStudentResponse;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewResponse"
      );

      firebaseFunction({
        task_id: taskId
      }).then((response) => {
        dispatch(setSubmittedStudentReview(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/task?submission_id=${response.data}`);
      });
    };

    const undoSubmitPeerReviewReply = () => {
      const submission_id = submittedStudentReply;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewReply"
      );

      firebaseFunction({
        submission_id: submission_id
      }).then((response) => {
        dispatch(setSubmittedStudentReview(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/task?submission_id=${response.data}`);
      });
    };

    const undoTaskFeedbackSubmission = () => {
      const taskId = sentFeedbackTask;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoTaskFeedbackSubmission"
      );

      firebaseFunction({ task_id: taskId })
        .then((response) => {
          dispatch(setSentFeedbackTask(0));
          setRefreshToken(refreshToken + 1);
        })
        .catch((err) => captureException(err));
    };

    if (shouldUndo) {
      if (submittedStudentTask > 0) {
        undoStudentTaskSubmission();
      } else if (submittedStudentReflection > 0) {
        undoSubmitPeerReviewReflection();
      } else if (submittedStudentResponse > 0) {
        undoSubmitPeerReviewResponse();
      } else if (submittedStudentReply > 0) {
        undoSubmitPeerReviewReply();
      } else if (sentFeedbackTask > 0) {
        undoTaskFeedbackSubmission();
      }
      dispatch(undo(false));
    }
  }, [shouldUndo]);

  // // Show flash message when there is undo data

  useEffect(() => {
    if (submittedStudentTask > 0) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.submission_msg",
            defaultMessage: "Task submitted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [alertsDuration, dispatch, submittedStudentTask]);

  useEffect(() => {
    if (sentFeedbackTask) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [alertsDuration, dispatch, sentFeedbackTask]);

  useEffect(() => {
    if (sentFeedbackTextTask > 0) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [alertsDuration, dispatch, sentFeedbackTextTask]);

  //for breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      }),
      url: "/tasks",
      resetCourse: true
    });
    course.id &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        text: course.name,
        course: course
      });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course.id, course.name]);

  // TODO: implament empty state
  if (!selectedCourseId) return null;
  else
    return (
      <ScrollBox className={classes.scrollBox}>
        {course.course_role === "Teacher" && (
          <ExportBanner
            bannerType={COURSE_MATERIAL_TYPE.TASK}
            setBannerShown={setBannerShown}
          />
        )}
        <Box
          className={classes.container}
          sx={{ marginTop: bannerShown ? "90px !important" : "0" }}>
          {["Teacher", "Admin"].includes(course.course_role) && (
            <TasksTeacherView course={course} refresh={refreshToken} />
          )}

          {course.course_role === "Student" && (
            <TasksStudentView
              course={course}
              refreshToken={refreshToken}
              refresh={refreshToken}
            />
          )}
        </Box>
      </ScrollBox>
    );
}
