import React from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "auto",
    flexFlow: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  rowsPerPage: {
    display: "flex",
    width: "auto",
    flexFlow: "row",
    alignItems: "center"
  },
  rowsPerPageSelect: {
    marginInline: theme.spacing(1),
    fontSize: theme.typography.fontSize
  },
  numberOfPaginatedUsers: {
    marginInline: theme.spacing(2)
  },
  paginationButton: {
    padding: 0,
    marginInline: theme.spacing(1),
    minWidth: "40px"
  }
}));

const TablePaginationFooter = ({
  currentPage,
  indexOfFirstUser,
  indexOfLastUser,
  sortedUsers,
  setCurrentPage,
  setRowsPerPage,
  rowsPerPage,
  totalUsers
}) => {
  const classes = useStyles();

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Box className={classes.container}>
      <Box className={classes.rowsPerPage}>
        <Typography variant="caption">Rows per page:</Typography>
        <FormControl variant="standard">
          <Select
            value={rowsPerPage}
            disableUnderline
            onChange={(e) => setRowsPerPage(e.target.value)}
            className={classes.rowsPerPageSelect}
            inputProps={{
              "aria-label": "Rows per page"
            }}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.numberOfPaginatedUsers}>
        <Typography variant="body2">
          {indexOfFirstUser + 1} -{" "}
          {indexOfLastUser > totalUsers ? totalUsers : indexOfLastUser} of{" "}
          {totalUsers}
        </Typography>
      </Box>
      <Button
        size="x-small"
        className={classes.paginationButton}
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="previous page">
        <ChevronLeftIcon />
      </Button>
      <Button
        size="x-small"
        className={classes.paginationButton}
        onClick={() => paginate(currentPage + 1)}
        disabled={indexOfLastUser >= sortedUsers.length}
        aria-label="next page">
        <ChevronRightIcon />
      </Button>
    </Box>
  );
};

export default TablePaginationFooter;
