// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables } from "../../firebase";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { captureException } from "../../utils/errorHandlers";
import { useQuery } from "../../hooks";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { textsAPI } from "../../api";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../redux/readerActionsSlice";
import { selectCourse } from "../../redux/coursesSlice";
import {
  dequeueFlashMessage,
  enqueueFlashMessage
} from "../../redux/userSlice";
import { selectTexts, reorderTexts, setTexts } from "../../redux/textsSlice";

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";
import HandleTextModal from "./HandleTextModal";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Link,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Button,
  Divider,
  IconButton
} from "@mui/material";
import { addSnackbarItem } from "../../redux/snackbarSlice";
import { selectAlertsDuration } from "../../redux/firestoreSelectors";
import { selectDarkMode } from "../../redux/firestoreSelectors";
import { TooltipWithIntl } from "../SharedComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { selectTasks, setTaskAndSubmissions } from "../../redux/tasksSlice";
import { startOfDay } from "date-fns";
import SortableText from "./SortableText";
import ExportModal from "../Tasks/TaskManager/courseMaterialExport/ExportModal";
import { COURSE_MATERIAL_TYPE } from "../Tasks/TaskManager/courseMaterialExport/consts";
import ExportBanner from "../Tasks/TaskManager/courseMaterialExport/ExportBanner";

//Styles
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexFlow: "column",
    position: "relative",
    justifyContent: "center",
    alignItems: "center"
  },
  pointer: {
    cursor: "pointer",
    height: "73px"
  },
  libraryContainer: {
    height: "100%",
    width: "83.333333%", // 10 culumns on a 12 column grid
    alignItems: "center",
    position: "relative",
    margin: "0 auto",

    [theme.breakpoints.up("desktop")]: {
      marginInline: "auto"
    }
  },
  tableContainer: {
    width: "auto", // 10 culumns on a 12 column grid,
    overflow: "hidden",
    marginTop: theme.spacing(3)
  },
  tableHead: {
    fontWeight: "800"
  },
  headerFont: {
    fontSize: "20px"
  },
  link: {
    color: theme.palette.text.primary
  },
  libraryHeader: {
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between"
  },

  cell: {
    marginTop: "16px",
    marginBottom: "16px",
    height: "auto"
  },
  authorName: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  readerViewContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerLtr: {
    left: 0
  },
  drawer: {
    width: "56px",
    height: "100vh",
    position: "fixed",
    background: "black",
    zIndex: 120,
    top: 0,
    bottom: 0,
    whiteSpace: "nowrap"
  },
  dirLtr: {
    direction: "ltr"
  },
  content: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)",
    display: "grid"
  },
  selectEmpty: {
    width: "50%",
    height: "80%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  fileName: {
    marginBlockStart: theme.spacing(1),
    color: "var(--light-text-secondary, rgba(0, 0, 0, 0.60))",
    maxWidth: "100%", // Adjust the value based on the available space
    whiteSpace: "nowrap"
  },
  categoriesAndActions: {
    display: "flex",
    justifyContent: "space-between",
    height: "73px"
  },
  disabledDeleteBtn: {
    color: theme.palette.grey.main
  }
}));

function DroppableComponent(texts) {
  const Component = (props) => {
    return (
      <SortableContext
        items={texts.map((t) => `sort-libary-texts-${t.id}`)}
        strategy={verticalListSortingStrategy}>
        <TableBody {...props}>{props.children}</TableBody>
      </SortableContext>
    );
  };
  Component.displayName = "DroppableComponent";
  return Component;
}

function Library() {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourse?.id
  );

  // Redux state
  const course = useSelector((state) => selectCourse(state, selectedCourseId));
  const texts = useSelector((state) => selectTexts(state, selectedCourseId));
  const darkMode = useSelector((state) => selectDarkMode(state));
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));
  const userRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );
  const tasks = useSelector((state) =>
    selectTasks(state, selectedCourseId?.toString())
  );
  // Ephemeral state
  const [openTexts, setOpenTexts] = useState(false);
  const [hoveredTextIndex, setHoveredTextIndex] = useState(null);
  const [selectedText, setSelectedText] = useState({});
  const today = startOfDay(new Date());
  const courseStart = startOfDay(new Date(course.course_start));
  const [exportModal, setExportModal] = useState(false);
  const [courseAndTeachers, setCourseAndTeachers] = useState([]);
  const [bannerShown, setBannerShown] = useState(false);

  // Bahavior

  const languages = ["he", "en"];
  const isTeacher = userRole === "Teacher";
  const handleTextHover = (index) => {
    setHoveredTextIndex(index);
  };

  const handleTextUnHover = () => {
    setHoveredTextIndex(null);
  };
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.library",
        defaultMessage: "Library"
      }),
      resetCourse: true
    });

    if (course.name) {
      parts.push({ text: course.name });
    }
    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, [course.name, dispatch]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    const taskFunctions = firebaseFunctions.httpsCallable(
      "tasks-taskFunctions"
    );
    isTeacher &&
      selectedCourseId &&
      taskFunctions({
        func_name: "readTeacherCourseTasks",
        course_id: selectedCourseId,
        courseStart,
        today
      }).then(({ data }) => {
        const payload = JSON.parse(data.payload);
        dispatch(setTaskAndSubmissions(payload));
      });
  }, [selectedCourseId, isTeacher]);

  useEffect(() => {
    const courseId = selectedCourseId;
    if (!courseId) return;
    httpCallables
      .coursesFunctions({
        func_name: "getCourseInstitutionId",
        course_id: courseId
      })
      .then((response) => {
        const { institution_id } = response.data;
        httpCallables
          .coursesFunctions({
            func_name: "getAllCoursesWithTeachersByInstitutionId",
            institution_id: institution_id
          })
          .then((response) => {
            const { data } = response;
            const filteredCourses = data.filter(
              (course) => course.id !== selectedCourseId
            );
            setCourseAndTeachers(filteredCourses);
          });
      });
  }, [selectedCourseId]);

  const onSortTexts = (activeTextId, overTextId, direction) => {
    if (direction) {
      const activeIndex = texts.findIndex((text) => text.id === activeTextId);
      overTextId = texts[activeIndex + direction]?.id ?? activeTextId;
    }

    const currentOrder = texts.map((t) => t.id);
    const activeIndex = currentOrder.indexOf(activeTextId);
    const overIndex = currentOrder.indexOf(overTextId);
    const reorderedtexts = reorder(texts, activeIndex, overIndex);
    textsAPI.reorderCourseTexts(reorderedtexts, course.id);
  };

  async function deleteTextFromLibrary(text) {
    try {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "comments.onDelete",
            defaultMessage: "Deleting..."
          }),
          duration: alertsDuration
        })
      );
      const textToDelete = text;
      const filteredTexts = texts.filter((text) => {
        if (text.id === textToDelete.id) return;
        return text;
      });
      const data = {
        text_id: text.id,
        course_id: selectedCourseId
      };
      dispatch(setTexts(filteredTexts));
      await httpCallables.deleteCourseText(data).then(() => {
        dispatch(dequeueFlashMessage());
        dispatch(
          addSnackbarItem({
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "undoDeleteText"
              }
            ],
            data: textToDelete,
            intlId: "comments.onDelete",
            intlDefaultMessage: "Text deleted",
            id: uuid()
          })
        );
      });
    } catch (error) {
      const ERROR_MSG = {
        message: intl.formatMessage({
          id: "error.deletingFailed",
          defaultMessage: `Failed to delete text ${text.name} with id:${text.id}`
        }),
        severity: "error",
        duration: alertsDuration
      };
      captureException(
        error,
        `Failed to delete text ${text.name} with id:${text.id}`
      );
      dispatch(enqueueFlashMessage(ERROR_MSG));
      return false;
    }
  }
  //Render
  return (
    <Box className={classes.container}>
      {!texts ? (
        <PangeaSpinner />
      ) : (
        <ScrollBox className={classes.scrollBox}>
          {course && course.course_role === "Teacher" && (
            <ExportBanner
              bannerType={COURSE_MATERIAL_TYPE.TEXT}
              setBannerShown={setBannerShown}
            />
          )}
          <Box
            className={classes.libraryContainer}
            sx={{ marginTop: bannerShown ? "90px !important" : "0" }}>
            <Box className={classes.libraryHeader}>
              <Typography className={classes.headerFont} variant="h6">
                {course
                  ? course.name
                  : intl.formatMessage({
                      id: "appBar.library",
                      defaultMessage: "Library"
                    })}
              </Typography>
              {selectedCourseId && isTeacher && (
                <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
                  {texts && texts.length ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      sx={{
                        marginInlineEnd: "8px",
                        color: "#2E7D32",
                        height: 36,
                        width: 114
                      }}
                      onClick={() => {
                        setExportModal(true);
                      }}>
                      <FormattedMessage
                        id="texts.share"
                        defaultMessage="Share Texts"
                      />
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    sx={{
                      height: 36,
                      width: 82
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpenTexts("Add");
                    }}
                    size="small">
                    Add Text
                  </Button>
                </Box>
              )}
            </Box>
            <Divider />
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}>
                      <FormattedMessage
                        id="library.author"
                        defaultMessage="Author"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}>
                      <FormattedMessage
                        id="library.title"
                        defaultMessage="Title"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}>
                      {/* <FormattedMessage
                        id="library.category"
                        defaultMessage="Category"
                      /> */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  component={
                    course && course.course_role === "Teacher"
                      ? DroppableComponent(texts)
                      : "tbody"
                  }>
                  {texts?.map((text, index) => {
                    const isTextHovered = index === hoveredTextIndex;
                    const textHasTasks = Boolean(
                      tasks.filter((task) => {
                        return task.text_id === text.id;
                      }).length
                    );
                    return (
                      <SortableText
                        key={text.id}
                        id={text.id}
                        index={index}
                        onSortTexts={onSortTexts}
                        course={course}
                        className={classes.pointer}
                        onMouseEnter={() => handleTextHover(index)}
                        onMouseLeave={handleTextUnHover}
                        hover={isTextHovered}
                        onClick={() =>
                          history.push(`/reader?text_id=${text.id}`)
                        }
                        aria-label={`${text.name} by ${text.author}. Press Enter to open.`}>
                        <TableCell className={classes.cell}>
                          <span
                            className={clsx(classes.link, classes.authorName)}>
                            {text.author}
                          </span>
                        </TableCell>
                        <TableCell className={classes.cell} align="left">
                          <span
                            className={clsx(classes.link, classes.textName)}>
                            {text.name}
                          </span>
                        </TableCell>
                        <TableCell
                          className={clsx(classes.categoriesAndActions)}
                          align="right">
                          {/* TODO - once categories mean something, uncomment this code */}
                          {/* <Box
                            sx={{
                              display: "row wrap"
                            }}
                          >
                            {text.categories && text.categories.length
                              ? text.categories
                                  .split(",")
                                  .map((item, index) => {
                                    return (
                                      <Chip
                                        className={classes.chip}
                                        key={index}
                                        label={item}
                                      />
                                    );
                                  })
                              : " "}
                          </Box> */}

                          {isTeacher && (
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "row",
                                width: "100px"
                              }}>
                              {isTextHovered && (
                                <>
                                  <TooltipWithIntl
                                    intlStringId={"text.edit.msg"}
                                    defaultMessage={"Edit text"}
                                    placement={"top"}>
                                    <IconButton
                                      aria-label={"edit text"}
                                      disabled={false}
                                      size={"medium"}
                                      onClick={() => {
                                        setOpenTexts("Edit");
                                        setSelectedText(text);
                                      }}>
                                      <EditOutlinedIcon />
                                    </IconButton>
                                  </TooltipWithIntl>
                                  {textHasTasks ? (
                                    <TooltipWithIntl
                                      intlStringId={"text.delete.msg"}
                                      defaultMessage={
                                        "Remove associated tasks \n before deleting the text"
                                      }
                                      placement={"bottom"}>
                                      <IconButton
                                        disabled={false}
                                        size={"medium"}
                                        onClick={() => {}}
                                        aria-label={"delete text"}
                                        className={classes.disabledDeleteBtn}>
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton>
                                    </TooltipWithIntl>
                                  ) : (
                                    <TooltipWithIntl
                                      intlStringId={"text.delete.msg"}
                                      defaultMessage={"Delete text"}
                                      placement={"top"}>
                                      <IconButton
                                        disabled={textHasTasks}
                                        size={"medium"}
                                        aria-label={"delete text"}
                                        onClick={async () => {
                                          !textHasTasks &&
                                            (await deleteTextFromLibrary(text));
                                        }}>
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton>
                                    </TooltipWithIntl>
                                  )}
                                </>
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </SortableText>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ScrollBox>
      )}
      {openTexts && (
        <HandleTextModal
          openTexts={openTexts}
          text={selectedText}
          setOpenTexts={setOpenTexts}
        />
      )}
      <ExportModal
        exportModal={exportModal}
        setExportModal={setExportModal}
        courseTexts={texts.filter(
          (text) => text.course_id === selectedCourseId
        )}
        type={COURSE_MATERIAL_TYPE.TEXT}
        courseAndTeachers={courseAndTeachers}
      />
    </Box>
  );
}

export default injectIntl(Library);
