// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { isEmpty } from "lodash";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import NavigationButtons from "./NavigationButtons";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  TextField,
  Typography,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import { selectAlertsDuration } from "../../../redux/firestoreSelectors";
import RichTooltip from "../../SharedComponents/RichTooltip";
import HandleTextModal from "../../library/HandleTextModal";
import AssignmentAdvancedSettings from "./AssignmentAdvancedSettings";
import { setTaskCreationStates } from "../../../redux/tasksSlice";

//Styles
const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: "50px"
  },
  selectText: {
    width: "80%"
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  text: {
    marginTop: "32px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  assignmentType: {
    marginTop: "24px",
    width: "100%"
  },
  popover: {
    left: "20px"
  },
  coach: {
    display: "flex",
    justifyContent: "flex-start"
  }
}));

function CreateAssignmentReadings({
  texts,
  text,
  setTextId,
  activeStep,
  setActiveStep,
  publishTask,
  saveTask,
  setIsDirty,
  readOnly = false,
  resetQuestions = null,
  taskType,
  setTaskType,
  setQuestions,
  botMode,
  setBotMode,
  lmsSyncDisabled,
  setLmsSyncDisabled,
  questions,
  isEdit
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  // Variables
  const validationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectReading",
    defaultMessage: "Select a text"
  });
  const [assignmentTypeAnchorEl, setAssignmentTypeAnchorEl] = useState(null);
  const showAssignmentTypeTooltip = Boolean(assignmentTypeAnchorEl);
  const assignmentTypeTooltipId = showAssignmentTypeTooltip
    ? "assisgnment-type-tooltip-popover"
    : undefined;
  const [textDisplay, setTextDisplay] = React.useState("");
  const [openTexts, setOpenTexts] = useState(false);
  const [uploadedText, setUploadedText] = useState(null);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const handleTooltipClick = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };
  const handleTooltipClose = (setAnchor) => {
    setAnchor(null);
  };

  const taskTypes = [
    {
      value: "guidedReading",
      text: intl.formatMessage({
        id: "task.type.guidedReading",
        defaultMessage: "Guided Reading"
      })
    },
    {
      value: "standard",
      text: intl.formatMessage({
        id: "task.type.standard",
        defaultMessage: "Standard"
      })
    }
  ];

  //Behavior
  function validateForm() {
    if (!text.id) {
      dispatch(
        enqueueFlashMessage({
          message: validationMessage,
          duration: alertsDuration,
          severity: "error"
        })
      );
      return false;
    } else return true;
  }

  useEffect(() => {
    if (uploadedText && texts.find((t) => t.id === uploadedText.id)) {
      setTextId(uploadedText.id);
      setUploadedText(null);
    }
  }, [texts, uploadedText]);

  const renderAssignmentTypeTooltipBody = () => {
    return (
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.type.standard",
            defaultMessage: "Standard"
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.readings.assignmentTypeTooltipStandard",
            defaultMessage: "Standard"
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.type.guidedReading",
            defaultMessage: "Guided reading"
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: "task.create.readings.assignmentTypeTooltipGuidedReading",
            defaultMessage: "Guided reading"
          })}
        </Typography>
      </Box>
    );
  };

  //Render
  return (
    <>
      <Box className={classes.section}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.create.readings.readingMaterial",
            defaultMessage: "Reading material"
          })}
        </Typography>
        <Divider className={classes.divider} />
        <Box className={classes.text}>
          <Autocomplete
            className={classes.selectText}
            disabled={readOnly}
            debug="true"
            id="selectReading"
            options={texts}
            getOptionKey={(text) => text.id}
            getOptionLabel={(option) => {
              return option ? option.author + " - " + option.name : "";
            }}
            renderInput={(params) => (
              <TextField
                readOnly={readOnly}
                {...params}
                name="select-text"
                aria-label="select-text-input"
                label={intl.formatMessage({
                  id: "task.create.readings.selectText",
                  defaultMessage: "Type the title or author name"
                })}
              />
            )}
            value={isEmpty(text) ? null : text}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              resetQuestions && resetQuestions();
              if (newValue && newValue.file_url) {
                setTextId(newValue.id);
                setIsDirty(true);
              }
            }}
            inputValue={textDisplay}
            onInputChange={(event, newInputValue) => {
              setTextDisplay(newInputValue);
            }}
          />
          <Typography variant="h6">
            {intl.formatMessage({
              id: "task.create.readings.orUpload",
              defaultMessage: "Or"
            })}
          </Typography>
          <Button
            variant="outlined"
            size="large"
            onClick={() => setOpenTexts("Add")}
            disabled={readOnly}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setOpenTexts("Add");
              }
            }}>
            {intl.formatMessage({
              id: "task.create.readings.upload",
              defaultMessage: "Upload"
            })}
          </Button>
          {openTexts && (
            <HandleTextModal
              openTexts={openTexts}
              setOpenTexts={setOpenTexts}
              setUploadedText={setUploadedText}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box sx={{ display: "flex", alignItems: "center", maxHeight: "32px" }}>
          <Typography className={clsx(classes.paddedContent)} variant="h6">
            {intl.formatMessage({
              id: "task.create.readings.assignmentType",
              defaultMessage: "Assignment type"
            })}
          </Typography>
          {!readOnly && (
            <IconButton
              onClick={(event) =>
                handleTooltipClick(event, setAssignmentTypeAnchorEl)
              }
              size="small"
              aria-haspopup={"true"}
              aria-expanded={showAssignmentTypeTooltip}
              aria-controls={assignmentTypeTooltipId}
              aria-label="Assignment type information">
              <InfoOutlinedIcon color="primary" />
            </IconButton>
          )}
          {showAssignmentTypeTooltip && (
            <Popover
              id={assignmentTypeTooltipId}
              open={showAssignmentTypeTooltip}
              anchorEl={assignmentTypeAnchorEl}
              onClose={() => handleTooltipClose(setAssignmentTypeAnchorEl)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              className={classes.popover}>
              <RichTooltip
                style={{ width: "300px" }}
                title="task.create.readings.assignmentType"
                bodyHtml={renderAssignmentTypeTooltipBody()}
              />
            </Popover>
          )}
        </Box>
        <Divider className={classes.divider} />
        <FormControl className={classes.assignmentType}>
          <InputLabel id="taskType-label">
            {intl.formatMessage({
              id: "task.create.readings.assignmentType",
              defaultMessage: "Assignment type"
            })}
          </InputLabel>
          <Select
            labelId="taskType-label"
            role={"combobox"}
            sx={{ width: "100%" }}
            variant="outlined"
            id={`taskType`}
            aria-label="task-subtitle-input"
            aria-controls="taskType"
            disabled={readOnly}
            label={intl.formatMessage({
              id: "task.create.readings.assignmentType",
              defaultMessage: "Assignment type"
            })}
            value={taskType}
            onChange={(event) => {
              setTaskType(event.target.value);
              setIsDirty(true);
            }}>
            {taskTypes.map((item) => {
              return (
                <MenuItem
                  className={classes.menu}
                  value={item.value}
                  key={item.value}>
                  {item.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ alignItems: "center" }}>
        <Typography
          role="button"
          onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
          color="primary"
          tabIndex="0"
          aria-expanded={showAdvancedSettings}
          aria-label={"Advanced settings"}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              setShowAdvancedSettings(!showAdvancedSettings);
            }
          }}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginBottom: "50px",
            width: "fit-content"
          }}>
          {intl.formatMessage({
            id: "task.create.readings.advancedSettings",
            defaultMessage: "Advanced settings"
          })}
          {showAdvancedSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Typography>

        {showAdvancedSettings && (
          <AssignmentAdvancedSettings
            readOnly={readOnly}
            isEdit={isEdit}
            botMode={botMode}
            setBotMode={setBotMode}
            lmsSyncDisabled={lmsSyncDisabled}
            setLmsSyncDisabled={setLmsSyncDisabled}
            setIsDirty={setIsDirty}
          />
        )}
      </Box>
      {setActiveStep && (
        <NavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          publishTask={publishTask}
          saveTask={saveTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}

// I added proptype sto start ceaning up eslint errors but i just put "any" - need to add correct proptypes here
CreateAssignmentReadings.propTypes = {
  texts: PropTypes.any,
  text_id: PropTypes.number,
  setTextId: PropTypes.func,
  activeStep: PropTypes.any,
  setActiveStep: PropTypes.any,
  publishTask: PropTypes.func,
  saveTask: PropTypes.func,
  setIsDirty: PropTypes.any,
  readOnly: PropTypes.any,
  resetQuestions: PropTypes.any,
  setQuestions: PropTypes.any,
  taskType: PropTypes.string,
  setTaskType: PropTypes.func,
  botMode: PropTypes.string,
  setBotMode: PropTypes.func,
  lmsSyncDisabled: PropTypes.bool,
  setLmsSyncDisabled: PropTypes.func,
  isEdit: PropTypes.bool
};

export default CreateAssignmentReadings;
